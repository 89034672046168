define("ember-get-config/index", ["exports", "@embroider/macros/runtime"], function (_exports, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global require */

  let configModulePath = `${(0, _runtime.config)("/__w/igm.infra/igm.infra/infra/live/igm-dev/us-east-2/epamdr/frontend_nv2/.terragrunt-cache/Hz0DQaPXDU9-AWH1b00CMkQREAI/T2gYKOwcEPeYZTnYyaspWkQfB5E/modules/ember-frontend/src/igm.frontend/node_modules/ember-get-config").modulePrefix}/config/environment`;
  var _default = _exports.default = require(configModulePath).default;
});